import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import {block} from 'bem-cn';
import './weeksLine.scss';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const TEST_DATA = Array.apply(null, {length: 8}).map((_, i) => ({
    week: moment().isoWeekday(1).subtract(i + 1, 'week'),
    value: i < 8 ? 100 - Math.round(15 * Math.random()) : 0,
    isActive: i === 0
}))

/**
 * 
 * @param {moment.Moment} day
 */
const formatWeekName = day => {
    const day1 = day.clone();
    const day2 = day.clone().add(6, 'days');
    return day1.month() === day2.month() ? 
        `${day1.format('D')} - ${day2.format('D MMMM')}`
        : `${day1.format('D MMMM')} - ${day2.format('D MMMM')}`
}

/**
 * 
 * @param {{
 *  size: ('normal' | 'small'),
 *  showValue: boolean,
 *  formatTooltip: any,
 *  colorRangeEnabled: boolean,
 *  data: Array.<{
 *      week: moment.Moment,
 *      value: Number,
 *      isActive: Boolean?
 *  }>
 * }} props 
 */
const WeeksLine = props => {
    const {data, size, formatTooltip, link} = props;
    const showValue = props.showValue === undefined ? true : false;
    const colorRangeEnabled = props.colorRangeEnabled === undefined ? true : false;
    const lineSize = {
        height: 70,
        width: 50
    };
    if (size === 'small') {
        lineSize.width = 10;
        lineSize.height = 35
    }

    // const currentWeek = data.filter(d => d.isActive)[0];
    const b = block('weeks-line');
    return <div className={b()} style={{height: lineSize.height}}>
        {_.sortBy((data || TEST_DATA), 'week').map((item, i) => {
            const heightScore = Math.round(100 * (item.value / 100));
            // 20 -> 80
            const colorScore = 140 * ((item.value - 35) / 100);
            return <PseudoLink  to={link && link(item)}
                style={{
                    height: `${heightScore}%`,
                    backgroundColor: colorRangeEnabled ? `hsla(${colorScore}, 65%, 42%, 1)` 
                        : item.isActive ? 'green' : '#73cc73',
                    width: lineSize.width
                }}
                className={b('bar', {active: item.isActive})} 
                key={item.week}
            >
                <p>{showValue && item.value}</p>
                <div className={b('bar_hover')} style={{display: 'none'}}>
                    {formatTooltip ? formatTooltip(item) : formatWeekName(item.week)}
                </div>
            </PseudoLink>
        })}
    </div>
}

const PseudoLink = props => {
    const {to} = props;
    if (to) {
        return <Link {...props} />
    } else {
        return <div {...props} />
    }
}

export default WeeksLine;