export default [
    '#c62828',
    '#6a1b9a',
    '#283593',
    '#0277bd',
    '#00838f',
    '#2e7d32',
    '#9e9d24',
    '#ff8f00',
    '#d84315',
    '#424242',
    '#212121'
].sort(() => (-1 + Math.floor(2.99999 * Math.random())) )