import Api from '../../api';

const USER_AUTH_UPDATED = 'USER_AUTH_UPDATED';
const CLAIMS_GATHERED = 'CLAIMS_GATHERED';

const defaultState = {
    user: null,
    claims: null
}

export const authReducer = (state = defaultState, action) => {
    const {payload} = action;
    switch(action.type) {
        case USER_AUTH_UPDATED:
            return {
                ...state,
                ...payload
            }
        case CLAIMS_GATHERED:
            return {
                ...state,
                claims: payload
            }
        default:
            return state;
    }
}

export const updateAuth = userInfo => ({
    type: USER_AUTH_UPDATED,
    payload: userInfo
})

export const checkClaims = () => {
    return async dispatch => {
        const claims = await Api.auth();
        dispatch({
            type: CLAIMS_GATHERED,
            payload: claims
        })
    }
}