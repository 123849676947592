// @flow
import React from 'react';
import DashSelect, {DashSelectType} from './DashSelect';

export const groupings = [
    {
        value: 'date',
        title: '📆 По дате',
        exclude: ['trips', 'weekDay']
    },
    {
        value: 'driver',
        dimensions: ['driverId', 'driverName', 'driverPhone'],
        title: '👨‍✈️ По водителям'
    },
    {
        value: 'bus',
        dimensions: ['carId', 'carName'],
        title: '🚌 По автобусам'
    },
    // {
    //     value: 'depStation',
    //     dimensions: [],
    //     title: '🚏 По остановке отправления'
    // },
    {
        value: 'source',
        dimensions: ['source'],
        title: '📊 По источнику продажи'
    },
    {
        value: 'paymentSource',
        dimensions: ['paymentSource'],
        title: '💳 По способу оплаты'
    },
    {
        value: 'direction',
        dimensions: ['directionId', 'directionName'],
        title: '↔️ По направлению'
    },
    {
        value: 'line',
        dimensions: ['lineId', 'lineName'],
        title: '↔️ По маршруту'
    },
    {
        value: 'service',
        dimensions: ['serviceId', 'serviceName'],
        title: 'По перевозчику'
    },
    {
        value: 'trips',
        title: '➿ По рейсам',
        dimensions: ['localDepDate'],
        exclude: ['date']
    },
    {
        value: 'weekDay',
        title: 'По дням недели',
        dimensions: ['weekDay'],
        exclude: ['date']
    },
    {
        value: 'isNewPass',
        title: 'Новые / вернувшиеся пассажиры',
        dimensions: ['isNewPass']
    }
]

const Groupings = (props: DashSelectType) => {
    return <DashSelect 
        items={groupings} {...props} 
        placeholder="Выберите группировку"
        max={2}
    />
}

export default Groupings;