// eslint-disable-next-line no-unused-vars
import moment from 'moment';

/**
 * 
 * @param {Number} n
 * @param {Number?} digits 
 */
export const formatNumber = (n, digits=2) => n !== null && n !== undefined ? typeof(n) == String ? n : n.toLocaleString(undefined, {maximumFractionDigits: digits}) : '';

/**
 * 
 * @param {moment.Moment} day 
 */
export const formatWeekName = day => {
    const day1 = day.clone();
    const day2 = day.clone().add(6, 'days');
    return day1.month() === day2.month() ? 
        `${day1.format('D')} - ${day2.format('D MMMM')}`
        : `${day1.format('D MMMM')} - ${day2.format('D MMMM')}`
}

/**
 * 
 * @param {Number} year 
 * @param {Number} isoWeek 
 * @returns {
 *  startDate: moment.Moment,
 *  endDate: moment.Moment
 * }
 */
export const unfoldWeek = (year, isoWeek) => {
    const startDate = moment({year});
    startDate.isoWeek(isoWeek).isoWeekday(1);
    const endDate = startDate.clone();
    endDate.isoWeekday(7);
    return {startDate, endDate};
}

export const cleanUpKeys = arr => {
    return arr.map(o => {
        const newObject = {};
        Object.keys(o).forEach(k => {
            const newKey = k.split('.')[1];
            newObject[newKey] = o[k];
        })
        return newObject
    })
}

const hourTitle = number => {
    switch(number % 10) {
        // case 0 && number === 0:
        //     return '';
        case 1:
            return `${number} час`;
        case 2:
            return `${number} часа`;
        case 3:
            return `${number} часа`;
        case 4:
            return `${number} часа`
        default:
            return `${number} часов`; 
    }
}

/**
 * 
 * @param {Number} minutes 
 */
export const formatMinutes = minutes => {
    const hours = Math.floor(minutes / 60);
    const minute = minutes % 60;
    return (hours > 0 ? hourTitle(hours) : '')
        + ` ${minute} минут`
}