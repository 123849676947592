import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { 
    Layout, 
    Menu, 
    Icon
} from 'antd';
import {block} from 'bem-cn';
import './body.scss';

const { 
    Sider
} = Layout;

const menus = [
    {
        title: 'Рейтинг маршрутов',
        icon: 'ordered-list',
        link: '/top'
    },
    {
        title: 'Бизнес-аналитика',
        icon: 'line-chart',
        link: '/bi'
    }
]


const Body = ({ match, children }) => {
    const b = block('body');
    const logout = () => {
        localStorage.removeItem('cube');
        firebase.auth().signOut();
    }
    return (
        <Layout className={b()}>
            <Sider breakpoint="lg">
                <Link to="/"><div className={b('logo')} />
                <div className={b('subtitle')}>Качество и цифры</div></Link>
                <Menu 
                    theme="dark" mode="inline" 
                    defaultSelectedKeys={['1']}
                    selectedKeys={menus.map(m => m.link).filter(link => document.location.pathname.startsWith(link))}
                >
                    {menus.map(m => (
                        <Menu.Item key={m.link}>
                            <Link to={m.link}>
                                <Icon type={m.icon} />
                                <span>{m.title}</span>
                            </Link>
                        </Menu.Item>
                    ))}
                    <Menu.Item key="exit" onClick={logout}>
                        <Icon type="close" />
                        <span>Выйти</span>
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                {children}
            </Layout>
        </Layout>
    )
};

export default withRouter(Body);