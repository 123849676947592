import React from 'react';
import {FiltersComponentTypes} from './types';
import { Button, Icon, Dropdown, Menu } from 'antd';
import SelectFilter from './SelectFilter';
import filtersTypes from './filterTypes';
import {FilterItemType} from './filterTypes';
import {block} from 'bem-cn';
import './filters.scss';

class Filters extends React.Component<FiltersComponentTypes> {
    enabledFilters() {
        const {filters} = this.props;
        return filtersTypes.filter(ft => filters.map(f => f.dimension).includes(ft.dimension)).map(ft => ({
            ...ft,
            values: filters.filter(f => f.dimension === ft.dimension)[0].values
        }));
    }
    unusedFilters() {
        const {filters} = this.props;
        return filtersTypes.filter(ft => !filters.map(f => f.dimension).includes(ft.dimension));
    }
    updateFilterValues(dimension, values) {
        const newValues = this.props.filters.map(f => ({
            ...f,
            values: f.dimension === dimension ? values : f.values
        }));
        this.props.updateFilters(newValues);
    }
    addFilter(filter: FilterItemType) {
        const filters = [
            ...this.props.filters,
            {
                dimension: filter.dimension,
                operator: filter.operator,
                values: []
            }
        ]
        this.props.updateFilters(filters);
    }
    deleteItem(dimension: String) {
        this.props.updateFilters(this.props.filters.filter(f => {
            return f.dimension !== dimension
        }))
    }
    render() {
        const b = block('filters');
        return <div className={b()}>
            {this.enabledFilters().map(f => (
                <SelectFilter 
                    context={this.props.filters}
                    onDelete={() => this.deleteItem(f.dimension)}
                    style={{marginRight: 10, marginBottom: 5, marginTop: 5, width: 'inherit'}}
                    key={f.dimension}
                    placeholder={f.placeholder} 
                    loadItems={f.loadItems}
                    selected={f.values}
                    onChange={v => this.updateFilterValues(f.dimension, v)}
                    id={f.dimension}
                />
            ))}
            <Dropdown
                overlay={<Menu>
                    {this.unusedFilters().map(f => <Menu.Item 
                        key={f.dimension}
                        onClick={() => this.addFilter(f)}
                    >
                        {f.title}
                    </Menu.Item>)}
                </Menu>}
            >
                <Button
                    type="link"
                >
                    Добавить фильтр
                    <Icon type="down" />
                </Button>
            </Dropdown>
        </div>
    }
}

export default Filters;