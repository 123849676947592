import React from "react";
import RootRoute from '../../routes';
import { Route } from "react-router-dom";
import { updateAuth, checkClaims } from './reducer';
import { connect } from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Spinner from '../../components/spinner';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import withFirebaseAuth from 'react-with-firebase-auth';

import { firebaseApp } from '../../config';

import moment from 'moment';
import 'moment/locale/ru';
import { Typography } from "antd";

moment.locale('ru');

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: document.location.pathname + document.location.search,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            recaptchaParameters: {
                size: 'invisible'
            },
            defaultCountry: 'RU',
            whitelistedCountries: ['RU', 'BY', 'UA']
        }
    ]
};

class Auth extends React.Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.user && this.props.user) {
            this.props.checkClaims();
        }
    }
    render() {
        const {
            signInWithGoogle,
            signOut,
            user,
            updateAuth,
            claims,
        } = this.props;
        window.user = user;
        window.signOut = signOut;
        window.signInWithGoogle = signInWithGoogle;
        updateAuth({
            user
        });
        return (
            <React.Fragment>
                {
                    user !== null ? (
                        claims ?
                            <Route path="/" component={RootRoute} />
                            : <Spinner height='100vh' tip='Загрузка...' />
                    )
                    : <AuthPage login={signInWithGoogle} />
                }
            </React.Fragment>
        );
    }
}

const AuthPage = ({ login }) => {
    return <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Typography>
            <Typography.Title>Контроль качества Атлас</Typography.Title>
        </Typography>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
}

const firebaseAppAuth = firebaseApp.auth();
const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const mapStateToProps = ({ authReducer: {claims} }) => ({claims});

const mapDispatchToProps = dispatch => ({
    updateAuth: userInfo => dispatch(updateAuth(userInfo)),
    checkClaims: () => {
        dispatch(checkClaims())
    }
})

export default withFirebaseAuth({
    providers,
    firebaseAppAuth,
})(
    connect(mapStateToProps, mapDispatchToProps)(Auth)
);
