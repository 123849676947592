import React from 'react';
import { Select, Input, Button, Icon } from 'antd';
import {matchPhrases} from '../../../utils/search';
import {FilterType} from '../../../types/cube';
import _ from 'lodash';

export type SelectFilterPropsType = {
    placeholder: ?String,
    loadItems: () => Promise<Array<{value: String, title: String}>>,
    selected: ?Array<String>,
    onChange: (values: Array<String>) => void,
    onDelete: () => void,
    style: React.StyleHTMLAttributes,
    context: Array<FilterType>,
    id: String
}

export type SelectFilterStateType = {
    items: Array<{value: String, title: String}>,
    filteredItems: Array<{value: String, title: String}>,
    loaded: Boolean, loading: Boolean, error: Object
}

export default class SelectFilter extends React.Component<SelectFilterPropsType> {
    state: SelectFilterStateType = {
        items: [],
        filteredItems: [],
        loaded: false,
        loading: false,
        error: null
    }
    selectRef = React.createRef()
    componentDidMount() {
        this.setState({items: [], loaded: false, loading: true});
        this.props.loadItems()
            .then(items => {
                this.setState({
                    items, loaded: true, loading: false
                }, () => this.filterItems())
            })
            .catch(error => this.setState({loaded: false, loading: false, error}))
    }
    onSelect() {
        this.selectRef.current.blur();
    }
    onChange(values) {
        this.props.onChange(values);
    }
    componentDidUpdate(prevProps) {
        // const oldContextValues = _.flatten(_.values(prevProps.context).map(v => v.values));
        // const newContextValues = _.flatten(_.values(this.props.context).map(v => v.values));
        if (!_.isEqual(this.props.context, prevProps.context)) {
            this.filterItems()
        }
    }
    filterItems(value) {
        const {context, id, selected} = this.props;
        const otherFilters = context.filter(c => c.dimension !== id && c.values.length > 0);

        this.setState({
            filteredItems: [...this.state.items.filter(i => {
                // return !value || matchPhrases(value, i.title);
                var match = true;
                otherFilters.forEach(of => {
                    const otherDimension = of.dimension.split('.')[1];
                    if (i[otherDimension]) {
                        if (!of.values.includes(i[otherDimension])) {
                            match = false;
                        }
                    }
                })
                if (!value || matchPhrases(value, i.title)) {
                    return match;
                }
                return false;
            })].sort((a, b) => {
                if (selected.includes(a.value)) {
                    return -1;
                }
                if (selected.includes(b.value)) {
                    return 1;
                }
                return 0;
            }).splice(0, 40)
        })
    }
    render() {
        const { placeholder } = this.props;
        const {filteredItems, loading} = this.state;
        return <Input.Group compact style={{...this.props.style, display: 'flex'}}>
            <Select
                value={this.props.selected}
                onSelect={this.onSelect.bind(this)}
                ref={this.selectRef}
                style={{ minWidth: 300 }} 
                placeholder={placeholder} 
                mode="multiple"
                loading={loading}
                disabled={loading}
                showArrow
                onChange={this.onChange.bind(this)}
                filterOption={() => true}
                onSearch={this.filterItems.bind(this)}
            >
                {filteredItems.map(i => <Select.Option 
                    value={i.value} 
                    key={i.value}
                    title = {i.title}
                >
                    {i.title}
                </Select.Option>)}
            </Select>
            <Button onClick={this.props.onDelete}><Icon type="delete" /></Button>
        </Input.Group>
    }
}