import React from 'react';
import { GraphProps } from './types';
import { formatNumber } from '../../../utils/format';
import colors from './colors';

import {
    ResponsiveContainer,
    LineChart, AreaChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';


const LineComponent = (props: GraphProps) => {
    const { data, timeDimensions, measures, dimensions, formats, type } = props;
    var Chart, ChartLine;
    switch(type) {
        case 'area':
            Chart = AreaChart;
            ChartLine = Area;
            break;
        case 'line':
            Chart = LineChart;
            ChartLine = Line;
            break;
        default:
            break;
    }
    return <ResponsiveContainer
        height={380}
    >
        <Chart
            data={data}
        >
            {timeDimensions.map(d => {
                return <XAxis
                    dataKey={d} key={d}
                />
            })}
            {dimensions.length === 0 && measures.map((m, i) => {
                return <ChartLine
                    key={m} 
                    yAxisId={ i < 2 ? m : measures[1]}
                    dataKey={m}
                    stroke={colors[i] || '#000'}
                    fill={type === 'area' ? colors[i] || '#000' : undefined}
                    strokeWidth={2}
                    type="monotone"
                    stackId={type === 'area' ? '1' : undefined}
                />
            })}
            {dimensions && dimensions.length === undefined && Object.keys(dimensions)
                .filter(d => !d.startsWith('_'))
                .map(d => {
                    return dimensions[d].map((g, i) => {
                        return <ChartLine
                            a={"a".startsWith}
                            key={g}
                            dataKey={v => {
                                return v[d][g] && v[d][g][measures[0]];
                            }}
                            strokeWidth={2}
                            name={g}
                            type="monotone"
                            stroke={colors[i] || '#000'}
                            fill={type === 'area' ? colors[i] || '#000' : undefined}
                            stackId={type === 'area' ? '1' : undefined}
                        />
                    })
            })}
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis width={100} yAxisId={measures[0]} orientation="left" tickFormatter={formatNumber} />
            <YAxis width={100} yAxisId={measures[1]} orientation="right" tickFormatter={formatNumber} />
            <Legend />
            <Tooltip
                formatter={(v, name) => {
                    var value = v;
                    if (typeof (v) === "number") {
                        value = formatNumber(v)
                    }
                    switch (formats[name]) {
                        case 'percent':
                            value = `${value} %`;
                            break;
                        default:
                            break;
                    }
                    return value
                }}
            />
        </Chart>
    </ResponsiveContainer>
}

export default LineComponent;