import React from 'react';
import { Table } from 'antd';
import { GraphProps } from './types';
import _ from 'lodash';
import {formatNumber} from '../../../utils/format';



class ChartTable extends React.Component<GraphProps> {
    defaultColumnParams(k) {
        return {
            sorter: (a, b) => this.filterValues(a[k], b[k])
        }
    }
    filterValues(a, b) {
        if (typeof(a) === "number" && typeof(b) === "number") {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        }
        if (typeof(a) === "string" || typeof(b) === "string") {
            return a.localeCompare(b);
        }
        return 0;
    }
    render() {
        const {rawDimensions, rawData, measures, timeDimensions} = this.props;
        return <Table
            dataSource={rawData}
            rowKey={r => _.values(r).map(v => v ? v.toString() : 'undefined').join('.')}
            pagination={false}
        >
            {timeDimensions.map(td => <Table.Column
                {...this.defaultColumnParams(td)}
                title={td}
                dataIndex={td}
                key={td}
            />)}
            {rawDimensions.filter(d => !d.startsWith('_')).map(d => <Table.Column 
                {...this.defaultColumnParams(d)}
                title={d}
                dataIndex={d}
                key={d}
            />)}
            {measures.map(m => <Table.Column
                {...this.defaultColumnParams(m)}
                title={m}
                dataIndex={m}
                render={v => typeof(v) === "number" ? formatNumber(v) : v}
                key={m}
            />)}
        </Table>
    }
}

export default ChartTable;