// @flow
import React from 'react';
import { Button, Dropdown, Icon, Menu, Tag } from 'antd';
import { block } from 'bem-cn';
import './dashselect.scss';
import _ from 'lodash';

export type DashSelectType = Object & {
    items: Array<{
        value: String,
        title: String,
        exclude: Array<String>
    }>,
    max: ?Number,
    value: ?Array<String>,
    placeholder: ?String,
    onChange: (values: Array<String>) => void,
    selected: Array<String>
}

class DashSelect extends React.Component<DashSelectType> {
    onSelect(value) {
        if (this.props.selected.length >= this.props.max) {
            return;
        }
        const { items } = this.props;
        const item = items.filter(i => i.value === value)[0];
        var selected = [...this.props.selected, item.value].filter(i => {
            return !item.exclude || !(item.exclude.indexOf(i) > -1);
        })
        this.props.onChange(selected);
    }
    onDeselect(value) {
        const selected = this.props.selected.filter(i => {
            return i !== value
        });
        this.props.onChange(selected);
    }
    selectedItems() {
        const {items, selected} = this.props;
        if (selected) {
            return selected.map(value => {
                return items ? items.filter(i => i.value === value)[0] : {
                    value,
                    title: value
                }
            })
        } else {
            return [];
        }
    }
    unselectedItems() {
        const {items, selected} = this.props;
        return items.filter(i => !selected.includes(i.value));
    }
    render() {
        const b = block('dash-filter');
        const { max } = this.props;
        return <div className={b()}>
            {this.selectedItems().map(i => (
                <Tag 
                    key={i.value}
                    onClose={() => this.onDeselect(i.value)}
                    closable
                    color="blue"
                >
                    {i.title}
                </Tag>
            ))}
            <Dropdown
                overlay={<MetricsMenu 
                    items={this.unselectedItems()} 
                    onAdd={this.onSelect.bind(this)}
                />}
                trigger={['click']}
                ref={this.selectRef}
                disabled={this.selectedItems().length >= max}
            >
                <Button
                    type="link"
                >
                    <Icon type="plus" />
                    Добавить
                </Button>
            </Dropdown>
        </div>
    }
}

const MetricsMenu = (props: {
    items: Array<{value: String, title: String}>,
    onAdd: (value: String) => void
}) => {
    const {items, onAdd} = props;
    const groups = _.groupBy(items, 'group');
    const groupNames = Object.keys(groups);
    return <Menu
        className='ant-dropdown-menu'
        onClick={({key}) => onAdd(key)}
        style={{minWidth: 200}}
    >
        {groupNames.length === 1 && items.map(m => <Menu.Item
            className="ant-dropdown-menu-item"
            key={m.value}
        >
            {m.title}
        </Menu.Item>)}
        {groupNames.length > 1 && groupNames.map(g => <Menu.SubMenu
            key={g}
            title={g}
        >
            {groups[g].map(i => <Menu.Item
                key={i.value}
                className="ant-dropdown-menu-item"
            >
                {i.title}
            </Menu.Item>)}
        </Menu.SubMenu>)}
    </Menu>
}

export default DashSelect;