import {FilterType} from '../../../types/cube';
import Cube from '../../../utils/cube';

export type FilterItemType = FilterType & {
    title: String,
    placeholder: String,
    loadItems: () => Promise<Array<{value: String, title: String}>>
}

const filtersTypes: Array<FilterItemType>  = [
    {
        dimension: 'Passengers.serviceId',
        operator: 'equals',
        values: [],
        title: 'По перевозчику',
        placeholder: 'Любой перевозчик',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'serviceId', 'serviceName');
            return data.map(d => ({value: d.serviceId, title: d.serviceName}));
        }
    },
    {
        dimension: 'Passengers.directionId',
        operator: 'equals',
        values: [],
        title: 'По направлению',
        placeholder: 'Любое направление',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'serviceId', 'directionId', 'directionName');
            return data.map(d => ({value: d.directionId, title: d.directionName, serviceId: d.serviceId}));
        }
    },
    {
        dimension: 'Passengers.lineId',
        operator: 'equals',
        values: [],
        title: 'По маршруту',
        placeholder: 'Любой маршрут',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'serviceId', 'directionId', 'lineId', 'lineName');
            return data.map(d => ({
                value: d.lineId, 
                title: d.lineName, 
                directionId: d.directionId, 
                serviceId: d.serviceId
            }));
        }
    },
    {
        dimension: 'Passengers.carId',
        operator: 'equals',
        values: [],
        title: 'По машине',
        placeholder: 'Все машины',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'carId', 'carName');
            return data.map(d => ({value: d.carId, title: d.carName}));
        }
    },
    {
        dimension: 'Passengers.driverId',
        operator: 'equals',
        values: [],
        title: 'По водителю',
        placeholder: 'Все водители',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'serviceId', 'driverId', 'driverName');
            return data.map(d => ({value: d.driverId, title: d.driverName, serviceId: d.serviceId}));
        }
    },
    {
        dimension: 'Passengers.source',
        operator: 'equals',
        values: [],
        title: 'По источнику бронирования',
        placeholder: 'Любой источник',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'source');
            return data.map(d => ({value: d.source, title: d.source}));
        }
    },
    {
        dimension: 'Passengers.paymentSource',
        operator: 'equals',
        values: [],
        title: 'По способу оплаты',
        placeholder: 'Любой способ оплаты',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'paymentSource');
            return data.map(d => ({value: d.paymentSource, title: d.paymentSource}));
        }
    },
    {
        dimension: 'Passengers.isNewPass',
        operator: 'equals',
        values: [],
        title: 'Новый / вернувшийся пассажир',
        placeholder: 'Любой',
        loadItems: async () => {
            const data = await Cube.getDimensions('bq', 'Passengers', 'isNewPass');
            return data.map(d => ({value: d.isNewPass, title: d.isNewPass}));
        }
    },
]

export default filtersTypes;