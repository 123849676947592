import { handleActions, createAction } from 'redux-actions';
import {ReportTypes} from '../../components/bi/Report/types';
import {QueryType} from '../../types/cube';
import moment from 'moment';
import {groupings as allGroupings} from '../../components/bi/Groupings';
import _ from 'lodash';

const getDefaultParam = (key, def=[]) => {
    const startParams = new URL(document.location.href).searchParams;
    return startParams.get(key) ? JSON.parse(startParams.get(key)) : def
}

const buildTimeDimensions = (
    cube: String, groupings: Array<String>, dateRange: [String, String], 
    granularity: String, dateParam: String
): Array<{
    dimension: String,
    dateRange: [String, String],
    granularity: String
}> => {
    // if (!groupings.includes('date')) { return []; }
    return [{
        dimension: [cube, dateParam].join("."),
        dateRange,
        granularity: groupings.includes('date') ? granularity : null
    }];
}

const buildQuery = (params: ReportTypes) => {
    const {cube, metrics, filters, groupings, dateRange, granularity, dateParam} = params;
    const query: QueryType = {
        measures: metrics.map(m => [cube, m].join(".")),
        dimensions: _.flatten(groupings.filter(g => g !== 'date').map(g => {
            const dimensions = allGroupings.filter(i => i.value === g)[0].dimensions;
            return dimensions.map(d => [cube, d].join("."))
        })),
        filters: filters.filter(f => f.values.length > 0).map(f => ({...f, values: f.values.map(v => v.toString())})),
        timeDimensions: buildTimeDimensions(cube, groupings, dateRange, granularity, dateParam),
        timezone: 'Europe/Moscow'
    };
    return query
}

const defaultState: () => ReportTypes = () => {
    const state = {
        cube: 'Passengers',
        metrics: getDefaultParam('metrics'),
        groupings: getDefaultParam('groupings'),
        filters: getDefaultParam('filters', []),
        dateRange: getDefaultParam('dateRange', [
            moment().subtract(7, 'day').format('YYYY-MM-DD'),
            moment().subtract(1, 'day').format('YYYY-MM-DD')
        ]),
        granularity: getDefaultParam('granularity', 'day'),
        dateParam: getDefaultParam('dateParam', 'depDate'),
    }
    state.query = buildQuery(state);
    return state;
}

export const updateParams = createAction(
    'UPDATE_REPORT_PARAMS',
    (key, value) => {
        const docUrl = new URL(document.location.href);
        docUrl.searchParams.set(key, JSON.stringify(value));
        window.history.pushState({}, '', docUrl.href);
        return {key, value}
    }
)

export const restoreDefaults = createAction('BI_RESTORE_DEFAULTS')

export default handleActions(
    {
        [updateParams]: (state, {payload: {key, value}}) => {
            const newState = {
                ...state,
                [key]: value
            }
            return {
                ...newState,
                query: buildQuery(newState)
            };
        },
        [restoreDefaults]: () => defaultState()
    },
    defaultState()
)