import React from 'react';
import { Table } from 'antd';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import reverse from 'lodash/reverse';
import { formatNumber } from '../../utils/format';
import StarIcon from './starIcon';


const FeedbackDriversTable = ({ data, pageSize }) => {
    var byDriver = values(groupBy(data, 'driverId')).map(feedbacks => ({
        driverId: feedbacks[0].driverId,
        driverName: feedbacks[0].driverName,
        averageRating: sumBy(feedbacks, 'rating') / feedbacks.length,
        countRating: feedbacks.length
    }))
    byDriver = reverse(sortBy(byDriver, 'averageRating'));
    return <Table
        pagination={{ position: "bottom", pageSize: pageSize || 6 }}
        dataSource={byDriver}
        rowKey="driverId"
    >
        <Table.Column 
            title="Водитель"
            dataIndex="driverName"
        />
        <Table.Column 
            sorter={(a, b) => parseFloat(a.averageRating) - parseFloat(b.averageRating)}
            title="Средняя оценка"
            dataIndex="averageRating"
            render={v => <div>
                <StarIcon rating={v} style={{marginRight: 8}} />
                {formatNumber(v, 1)}
            </div>}
        />
        <Table.Column 
            sorter={(a, b) => parseInt(a.countRating) - parseInt(b.countRating)}
            title="Количество оценок"
            dataIndex="countRating"
        />
    </Table>
}

export default FeedbackDriversTable;