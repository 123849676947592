import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import StarIcon from './starIcon';

const FeedbackTable = ({ data, pageSize }) => {
    return <Table
        pagination={{ position: "bottom", pageSize: pageSize || 6 }}
        dataSource={data}
        rowKey={r => `${[r.userId, r.localDatetime].join("-")}`}
    >
        <Table.Column 
            dataIndex="rating"
            sorter={(a, b) => parseInt(b.rating) - parseInt(a.rating)}
            title="Оценка"
            render={(r) => <div>
                <StarIcon rating={r} style={{marginRight: 10}} />
                {r}
            </div>}
        />
        <Table.Column
            title="Водитель"
            filters={uniqBy(data, 'driverId').map(d => ({
                text: d.driverName,
                value: d.driverId
            }))}
            onFilter={(v, r) => r.driverId === v}
            dataIndex="driverName"
        />
        <Table.Column
            title="Машина"
            dataIndex="carName"
            filters={uniqBy(data, 'carId').map(d => ({
                text: `${d.carName} (${d.carNum})`,
                value: d.carId
            }))}
            onFilter={(v, r) => v === r.carId}
            render={(_, r) => `${r.carName} (${r.carNum})`}
        />
        <Table.Column
            title="Рейс"
            key="trip"
            sorter={(a, b) => moment(a.utcDatetime).diff(moment(b.utcDatetime))}
            render={(_, r) => `${r.lineName}, ${moment.utc(r.localDatetime).utc().format('D MMM в HH:mm')}`}
        />
        <Table.Column
            width="40%"
            title="Комментарий"
            dataIndex="userComment"
            // render={(_, r) => `${r.lineName}, ${moment.utc(r.localDatetime).utc().format('D MMM в HH:mm')}`}
        />
    </Table>
}

export default FeedbackTable;