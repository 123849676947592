import React from 'react';
import Line from './Line';
import _ from 'lodash';
import moment from 'moment';
import { Tabs, Icon } from 'antd';
import Table from './Table';
import Bar from './Bar';
import memoize from 'memoize-one';

export type GraphComponentTypes = {
    data: CubeResults
}

const prepareData = ({annotation: {dimensions, timeDimensions, measures}, data}) => {
    var dimensionValues = _.values(dimensions).map(d => d.shortTitle).filter((d: String) => !d.startsWith('_'));
    var timeDimensionsCleared = timeDimensions;
    if (_.keys(timeDimensions).length > 0 && data.length > 0) {
        if (!data[0][_.keys(timeDimensions)[0]]) {
            timeDimensionsCleared = {};
        }
    }
    const formats = {};
    var res = data.map(d => {
        return Object.keys(d).reduce((acc, k) => {
            const item = dimensions[k] || timeDimensionsCleared[k] || measures[k];
            if (!item) return acc;
            acc[item.shortTitle] = d[k];
            if (timeDimensionsCleared[k]) {
                acc[timeDimensionsCleared[k].shortTitle] = moment(d[k]).format('DD.MM.YYYY');
            }
            acc.types = acc.types || {};
            if (item.format) {
                formats[item.shortTitle] = item.format;
            }
            return acc
        }, {})
    });
    const rawRes = res;
    if (_.values(timeDimensionsCleared).length > 0 && _.values(dimensions).length > 0) {
        res = _.values(_.groupBy(res, _.values(timeDimensionsCleared)[0].shortTitle));
        dimensionValues = {};
        res = res.map(items => items.reduce((acc, value) => {
            _.values(dimensions).forEach(d => {
                const dimKey = d.shortTitle;
                const dimValueKey = value[dimKey];
                if (!acc[dimKey]) { acc[dimKey] = {}};
                if (!dimensionValues[dimKey]) {dimensionValues[dimKey] = []};
                if (!dimensionValues[dimKey].includes(dimValueKey)) {
                    dimensionValues[dimKey].push(dimValueKey)
                };
                acc[dimKey][dimValueKey] = {};
                _.values(measures).forEach(m => {
                    const measureKey = m.shortTitle;
                    acc[dimKey][dimValueKey][measureKey] = value[measureKey];
                    formats[dimValueKey] = formats[measureKey]
                })
            })
            _.values(timeDimensionsCleared).forEach(td => {
                const tdKey = td.shortTitle;
                acc[tdKey] = value[tdKey];
            })
            return acc
        }, {}))
    }
    return {
        data: res,
        rawData: rawRes,
        formats,
        timeDimensions: _.values(timeDimensionsCleared).map(d => d.shortTitle),
        dimensions: dimensionValues,
        rawDimensions: _.values(dimensions).map(d => d.shortTitle),
        measures: _.values(measures).map(d => d.shortTitle)
    }
}

export default class Graph extends React.Component {
    state: {currentTab: ?String, preparedData: {}} = {
        currentTab: null,
        preparedData: null
    }
    prepareData = memoize(
        data => {
            if (data) {
                this.setState({preparedData: prepareData(data)})
            }
        }
    )
    componentDidUpdate() {
        this.prepareData(this.props.data);   
    }
    componentDidMount() {
        const url = new URL(document.location.href);
        const graphType = url.searchParams.get('graphType');
        this.setState({currentTab: graphType ? JSON.parse(graphType) : 'line'})
        this.prepareData(this.props.data);
    }
    invalidForGraph(preparedData): String | false {
        if (!preparedData) {
            return 'Нет данных';
        }
        if (preparedData.timeDimensions.length === 0) {
            return 'Добавбьте группировку по дате'
        }
        if (preparedData.dimensions.length > 20) {
            return `Слишком много получилось линий (${preparedData.dimensions.length} штук)`
        }
        if (
                (preparedData.timeDimensions.length
                    + preparedData.rawDimensions.length) > 1
                && preparedData.measures.length > 1
        ) {
            return `Уберите группировки ${preparedData.rawDimensions.map(d => `"${d}"`).join(', ')} `
                + `или оставьте только одну метрику`
        }
        return false;
    }
    invalidForBar(preparedData) {
        if (!preparedData) {
            return 'Нет данных';
        }
        if (preparedData.dimensions.length !== 1) {
            return 'Должна быть одна группировка (кроме даты)'
        }
        if (preparedData.timeDimensions.length > 0) {
            return 'Не должно быть группировок по дате'
        }
        if (preparedData.measures.length !== 1) {
            return 'Должно быть одно измерение'
        }
        return false;
    }
    changeTab(currentTab) {
        this.setState({currentTab});
        const url = new URL(document.location.href);
        url.searchParams.set('graphType', JSON.stringify(currentTab));
        window.history.pushState(null, null, url.href);
    } 
    render() {
        const {preparedData} = this.state;
        return <div>
            {preparedData && <Tabs 
                onChange={this.changeTab.bind(this)}
                tabBarStyle={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                activeKey={this.state.currentTab}
            >
                <Tabs.TabPane
                    disabled={this.invalidForGraph(preparedData) !== false}
                    tab={<span><Icon type="line-chart" />График</span>}
                    key="line"
                >
                    {this.invalidForGraph(preparedData) === false ? 
                        <Line 
                            {...preparedData}
                            type="line"
                        />
                        : <div>{this.invalidForGraph(preparedData)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane 
                    disabled={this.invalidForGraph(preparedData) !== false}
                    tab={<span><Icon type="area-chart" />Области</span>}
                    key="area"
                >
                    {this.invalidForGraph(preparedData) === false ? 
                        <Line 
                            {...preparedData}
                            type="area"
                        />
                        : <div>{this.invalidForGraph(preparedData)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><Icon type="bar-chart"/>Столбцы</span>}
                    disabled={this.invalidForBar(preparedData) !== false}
                    key="bar"
                >
                     {this.invalidForBar(preparedData) === false ?
                        <Bar 
                            {...preparedData}
                        />
                        : <div>{this.invalidForBar(preparedData)}</div>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span><Icon type="table"/>Таблица</span>}
                    key="table"
                    disabled={false}
                >
                    <Table 
                        {...preparedData}
                    />
                </Tabs.TabPane>
            </Tabs>}
        </div>
    }
}