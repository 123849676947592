import React from 'react';
import { GraphProps } from './types';
import colors from './colors';
import { formatNumber } from '../../../utils/format';
import {
    ResponsiveContainer,
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default class extends React.Component<GraphProps> {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

    render() {
        const {data, dimensions, measures, formats} = this.props;
        return (
            <ResponsiveContainer
                width="100%"
                height={280}
            >
                <BarChart
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {dimensions.map(d => (
                        <XAxis dataKey={d} key={d} />
                    ))}
                    <YAxis />
                    <Tooltip formatter={(v, name) => {
                        var value = v;
                        if (typeof (v) === "number") {
                            value = formatNumber(v)
                        }
                        switch (formats[name]) {
                            case 'percent':
                                value = `${value} %`;
                                break;
                            default:
                                break;
                        }
                        return value
                    }} />
                    <Legend />
                    {measures.map((m, i) => (
                        <Bar key={m} dataKey={m} fill={colors[i]} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
