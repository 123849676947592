import React from 'react';
import { Typography, Button, Icon, Divider, Card, Tabs } from 'antd';
import { block } from 'bem-cn';
import { Link } from 'react-router-dom';
import './morda.scss';
import reports, {groups, ReportExampleType} from './reports';
import _ from 'lodash';

class Morda extends React.Component {
    render() {
        const b = block('bi-morda');
        const groupedReports = _.groupBy(reports, 'group.title');
        return <div className={b()}>
            <div className={b('header')}>
                <div>
                    <Typography.Title>Бизнес аналитика</Typography.Title>
                    <Typography.Title level={4}>Инструменты для анализа эффективности</Typography.Title>
                </div>
                <Link to="/bi/report" >
                    <Button
                        className={b('header_cta').toString()}
                        type="primary"
                        size="large"
                    >
                        <Icon type="dot-chart" />
                        Построить свой отчёт
                    </Button>
                </Link>
            </div>
            <div className={b('body').toString()}>
                <Divider />
                <Typography.Title level={3}>Галерея отчётов</Typography.Title>
                <Tabs
                    tabPosition="top"
                    tabBarStyle={{textAlign: 'center'}}
                >
                    {_.orderBy(_.values(groups), 'number').map(g => (
                        <Tabs.TabPane tab={g.title} key={g.title}>
                            <ReportGroup reports={groupedReports[g.title]} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </div>
    }
}

const buildUrl = params => {
    const url = new URL(document.location.href);
    url.pathname = '/bi/report';
    Object.keys(params).forEach(k => {
        url.searchParams.set(k, JSON.stringify(params[k]));
    })
    return url.pathname + url.search;
}

const ReportGroup = (params: {
    reports: Array<ReportExampleType>
}) => {
    const b = block('bi-morda');
    const {reports} = params;
    return (
        <div className={b('cards')} gutter={15}>
            {reports && reports.map(r => (
                <div
                    key={r.title}
                    className={b('cards-item')}
                    style={{
                        width: `${(100 * (r.span || 8) / 24).toFixed(0)}%`
                    }}
                >
                    <Link to={buildUrl(r.params)}>
                        <Card
                            cover={
                                r.image && <img src={require(`./reports/images/${r.image}`)} alt={r.title} />
                            }
                            hoverable
                        >
                            <Card.Meta
                                title={r.title}
                                description={r.description}
                            />
                        </Card>
                    </Link>
                </div>
            ))}
        </div>
    )
}

export default Morda;