import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

class API {
    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })
    }
    auth() {
        return new Promise((resolve, reject) => {
            firebase.auth().currentUser.getIdTokenResult()
                .then(({token}) => {
                    this.client.get('/auth', {
                        headers: {
                            'Authorization': token
                        }
                    })
                    .then(({data}) => {
                        resolve(data);
                    })
                    .catch(e => {
                        firebase.auth().signOut();
                        reject(e);
                    });
                })
                .catch(reject);
        })
    }
    reestr = {
        stationsSuggest: (q: String) => {
            return this.client.get('/reestr/stops', {
                params: {q}
            })
        },
        schedule: (from: String, to: String) => {
            return this.client.get('/reestr/schedule', {
                params: {from, to}
            })
        }
    }
}

export default new API();