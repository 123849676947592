import React from 'react';
import Body from '../pages/Body';
import DirectionRoute from './direction';
import BI from './bi';
import Top from './top';
import Reestr from './reestr';
import {Route, Redirect} from 'react-router-dom'

const RootRoute = ({match}) => <Body>
    <Route exact path="/" render={() => <Redirect to="/top" />} />
    <Route path={`${match.url}bi`} component={BI} />
    <Route path={`${match.url}reestr`} component={Reestr} />
    <Route path={`${match.url}direction`} component={DirectionRoute} />
    <Route path={`${match.url}top`} component={Top} />
</Body>

export default RootRoute;