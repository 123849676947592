// @flow
import React from 'react';
import DashSelect, {DashSelectType} from './DashSelect';

const groups = {
    pax: 'Пассажиропоток',
    cancellations: 'Отмены',
    sales: 'Продажи',
    revenue: 'Деньги',
    logistic: 'Логистика',
    retention: 'Возвращаемость'
}

const metrics = [
    {
        value: 'totalRevenue',
        title: '💰 Выручка',
        group: groups.revenue
    },
    {
        value: 'avgTicketPrice',
        title: '💲 Средняя стоимость билета',
        group: groups.revenue
    },
    {
        value: 'revenuePerTrip',
        title: '💸 Средняя выручка на рейс',
        group: groups.revenue
    },
    {
        value: 'refundRevenue',
        title: 'Комиссия за возврат',
        group: groups.revenue
    },
    {
        value: 'pax',
        title: '👨‍🦱 Количество пассажиров',
        group: groups.pax
    },
    {
        value: 'newUsersShare',
        title: '％ Доля новых пассажиров',
        group: groups.pax
    },
    {
        value: 'lateCancellationsCount',
        title: '🌚 Количество поздних отмен',
        group: groups.cancellations
    },
    {
        value: 'lateCancellationsShare',
        title: '％ Доля поздних отмен',
        group: groups.cancellations
    },
    {
        value: 'cancellationsCount',
        title: '❌ Количество отмен',
        group: groups.cancellations
    },
    {
        value: 'cancellationsShare',
        title: '％ Доля отмен',
        group: groups.cancellations
    },
    {
        value: 'salesCount',
        title: '🎟 Количество продаж',
        group: groups.sales
    },
    {
        value: 'salesRevenue',
        title: '💰 Выручка с продаж (без учёта отмен)',
        group: groups.sales
    },
    {
        value: 'trips',
        title: '🚌 Количество рейсов',
        group: groups.logistic
    },
    {
        value: 'paxPerTrip',
        title: 'В среднем пассажиров на рейс',
        group: groups.logistic
    },
    {
        value: 'seatsAvailable',
        title: 'Количество пассажиромест',
        group: groups.logistic
    },
    {
        value: 'fillShare',
        title: 'Заполняемость',
        group: groups.logistic
    },
    {
        value: 'returnedInWeekShare',
        title: '% вернувшихся в течении недели',
        group: groups.retention
    },
    {
        value: 'returnedIn3WeeksShare',
        title: '% вернувшихся в течении 3 недель',
        group: groups.retention
    },
    {
        value: 'returnedInWeekCount',
        title: 'Вернулись в течении недели',
        group: groups.retention
    },
    {
        value: 'returnedIn3WeeksCount',
        title: 'Вернулись в течении 3 недель',
        group: groups.retention
    }
]

const Metrics = (props: DashSelectType) => {
    return <DashSelect 
        {...props}
        items={metrics}
        placeholder="Выберите метрику"
        max={4}
    />
}

export default Metrics;